import React from 'react';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import ContrastContext from '../context/contrastContext';
import Hero from '../components/Hero';
import Section from '../components/Section';
import Block from '../components/Block';
import { MarkdownContent } from '../components/Content';

const ContactPage = ({ title, heroImage, heroContent, blocks, archivedContentTitle }) => {
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactOrg, setContactOrg] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { isHighContrastEnabled } = useContext(ContrastContext);

  const isSubmitEnabled = () => {
    if (contactName && contactEmail && contactMessage) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let contactForm = document.getElementById('contactForm');
    let formData = new FormData(contactForm);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted');
        sendContactSubmissionEvent();
        setContactName('');
        setContactEmail('');
        setContactOrg('');
        setContactMessage('');
        setIsFormSubmitted(true);
      })
      .catch((error) => alert(error));
  };

  function sendContactSubmissionEvent() {
    if (typeof window.plausible === 'function') {
      window.plausible('Contact Submission', {
        callback: () => console.info('Sent Contact Submission event'),
      });
    } else {
      console.warn('Ignoring Contact Submission Event: localhost');
    }
  }

  const submitInputClass = () => {
    if (isSubmitEnabled()) {
      if (isHighContrastEnabled) {
        return 'cursor-pointer bg-primaryHC hover:bg-primaryHoverHC focus:bg-primaryPressedHC';
      }
      return 'cursor-pointer bg-primary hover:bg-primaryHover focus:bg-primaryPressed';
    } else {
      if (isHighContrastEnabled) {
        return 'cursor-not-allowed bg-gray500';
      }
      return 'cursor-not-allowed bg-primaryDisabled';
    }
  };

  return (
    <>
      <Hero image={heroImage} />
      <Section>
        <div className="container flex flex-col md:flex-row py-12 gap-x-12">
          <div className="pb-4 md:w-1/3">
            <h2>
              <span className="bg-theme-underline">{title}</span>
            </h2>
          </div>
          <div className="md:w-2/3 text-base">
            <MarkdownContent content={heroContent} />
          </div>
        </div>
      </Section>
      <Section colorScheme="white" overlaySide="left" overlayLength="large">
        <div className="container py-8 text-base">
          <form
            id="contactForm"
            name="contact"
            onSubmit={handleSubmit}
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="archivedContentTitle" value={archivedContentTitle} />
            <label htmlFor="name" className="block">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              className="form-input block w-full rounded-xl bg-transparent mb-2"
              required
            />
            <label htmlFor="email" className="block">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              className="form-input block w-full rounded-xl bg-transparent mb-2"
              required
            />
            <label htmlFor="org" className="block">
              Organization
            </label>
            <input
              type="text"
              name="org"
              value={contactOrg}
              onChange={(e) => setContactOrg(e.target.value)}
              className="form-input block w-full rounded-xl bg-transparent mb-2"
            />
            <label htmlFor="messsage" className="block">
              Message
            </label>
            <textarea
              name="message"
              value={contactMessage}
              onChange={(e) => setContactMessage(e.target.value)}
              className="form-input block w-full rounded-xl bg-transparent mb-2"
              rows="12"
              required
            />
            <div className="flex flex-col md:flex-row mt-12 gap-x-12">
              <div className="md:w-1/3">
                <input
                  type="submit"
                  value="Submit"
                  name="submit"
                  id="submit"
                  className={`button inline-block px-12 py-3 rounded-full text-white ${submitInputClass()}`}
                />
              </div>
              <div className="md:w-2/3 md:text-right">
                {isFormSubmitted && (
                  <span className="inline-block my-3 font-medium">
                    Form Submitted, Thank you.
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </Section>
      {blocks &&
        blocks.map((block, index) => (
          <Block
            key={index}
            colorScheme={block.colorScheme}
            overlayLength={block.overlayLength}
            overlaySide={block.overlaySide}
            leftContent={block.left}
            rightContent={block.right}
          />
        ))}
    </>
  );
};

ContactPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      colorScheme: PropTypes.string,
      overlaySide: PropTypes.string,
      overlayLength: PropTypes.string,
      leftContent: PropTypes.string,
      rightContent: PropTypes.string,
    })
  ),
  archivedContentTitle: PropTypes.string,
};

export default ContactPage;
