import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import ContactPage from './ContactPage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

// This is a loose clone of the Contact Template, but with the data pulled from the archive page
const ArchivedPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.markdownRemark;
  const slug = pageContext.slug;
  
  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <ContactPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          blocks={page[pageContext.locale].blocks || page[defaultLocale].blocks}
          archivedContentTitle={slug}
          emailTo="archive@disinformationindex.org"
        />
        <Section colorScheme="white" overlaySide="left" overlayLength="large">
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

ArchivedPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArchivedPageTemplate;

// This query is designed to find the archive page to pull the text from it; non-dynamic
export const archivedPageQuery = graphql`
  query ArchivedPage {
    markdownRemark(frontmatter: {en: {templateKey: {eq: "ArchivedPageTemplate"}}}) {
      fields {
        slug
      }
      frontmatter {
        en {
          title
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
          hero
          blocks {
            colorScheme
            left
            overlayLength
            overlaySide
            right
          }
        }
        de {
          title
          hero
          blocks {
            colorScheme
            left
            overlayLength
            overlaySide
            right
          }
        }
      }
    }
  }
`;